<script>
import Global from "@/stores/GlobalVariables.js";
import Mdd from 'mobile-device-detect';
import axios from "axios";

export default {
    data() {
        return {
           Global,
           publicPath: process.env.BASE_URL
        }
    },
    methods: {
        isNullOrEmpty: function(value){
            return (value == null || !value || value == undefined || value == "" || value.length == 0);
        },
        getGAEncodeUrl: function(callback){
            var vm = this;
            axios
            .get(Global.apiUrl + "/opportunities/" + Global.email)
            .then(function(response) {
                if(!vm.isNullOrEmpty(response.data)){
                    Global.GAEncodeUrl.agent = response.data.agent;
                    Global.GAEncodeUrl.customer = response.data.customer;
                    Global.guidelineUrl = JSON.parse(response.data.guidelineUrl);
                }
                if (callback && typeof callback === "function") {
                    callback();
                }
            })
            .catch(function(error) {
                if(error?.response?.status === 401){
                    vm.$router.go();
                }
                console.log(error);
            })
        },
        getTokenNjsmcs: async function(){
            return axios.post(Global.apiUrl + "/microservice/njsmcs")
            .then(response => {
                return response.data.t;
            })
            .catch(error => {
                console.error(error)
                return null;
            });
        },
        getTokenMcs: async function(){
            return axios.post(Global.apiUrl + "/microservice/mcs", {}, { headers : { Authorization : "Bearer " + (Global.jwt !== "" ? Global.jwt : Global.apiUser.jwt)}})
            .then(response => {
                Global.mcsToken = response.data.t
                return response.data.t;
            })
            .catch(error => {
                console.error(error)
                return null;
            });
        },
        getCurrentDateTime: function() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();
            var time = String(today.getHours()).padStart(2, "0") +  String(today.getMinutes()).padStart(2, "0") +  String(today.getSeconds()).padStart(2, "0")
            today = yyyy + "-" + mm + "-" + dd + "_" + time;

            return today;
        },
        getCurrentDate: function() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + "-" + mm + "-" + dd;

            return today;
        },
        getCurrentDateNoDash: function(){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            return yyyy + mm + dd;
        },
        getCustomerShortLink: async function () {
            var vm = this;
            var token = Global.njsToken ? Global.njsToken : await vm.getTokenNjsmcs();
            if (token) {
                await axios
                .post(
                    Global.njsmcsURL + "/utility/shortLink",
                    {
                    domainUriPrefix: "https://roojai.page.link",
                    link: Global.GAEncodeUrl.customer + '&lang=' + Global.locale,
                    },
                    {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                    }
                )
                .then((response) => {
                    Global.customerShortLink = response.data.shortLink;
                })
                .catch((error) => {
                    console.error(error);
                });
            }
        },
        getProductShortLinks: async function(){
            var vm = this;
            if(['Ground agent', 'Traditional broker'].includes(Global.brokerDetails.accountSource) || vm.isThairath(Global.brokerDetails)){
                Global.productShortlink.productList = [{ "product":"allProduct" },{ "product":"carInsurance" },{ "product":"motorBikeInsurance" }];
            }
            Global.productShortlink.selected = "";
            Global.productShortlink.isError = false;
            var token = Global.njsToken ? Global.njsToken : await vm.getTokenNjsmcs();
            if (token) {       
                Global.productShortlink.productList.forEach((item) => {
                    let normalLink = vm.isThairath(Global.brokerDetails) ? Global.GAEncodeUrl.customer + '&lang=' + Global.locale + '&productType=' + item.product + '&isWL=true' : Global.GAEncodeUrl.customer + '&lang=' + Global.locale + '&productType=' + item.product;
                    axios.post(
                            Global.njsmcsURL + "/utility/shortLink", {                            
                            domainUriPrefix: "https://roojai.page.link",
                            link: normalLink,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        }
                    ).then((response) => {                        
                        item.shortlink = response.data.shortLink;
                        if(item.product === 'allProduct'){
                            Global.productShortlink.selected = item.shortlink;
                        }
                    }).catch((error) => {
                        Global.productShortlink.isError = true;
                        console.error(error);
                    });
                });
            }
        },
        getCustomerShortLinkQRCode: async function () {
            var vm = this;
            var token = Global.mcsToken ? Global.mcsToken : await vm.getTokenMcs();
            if (token) {
                await axios
                .post(
                    Global.mcsURL + "/barcodeGenerator/generateQRCode",
                    {
                    code: Global.customerShortLink,
                    width: 200,
                    height: 200,
                    },
                    {
                    headers: { Authorization: "Bearer " + token },
                    responseType: "arraybuffer",
                    }
                )
                .then((response) => {
                    const base64 = btoa(
                    new Uint8Array(response.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ""
                    )
                    );
                    Global.customerQRCode =
                    "data:" + response.headers["content-type"] + ";base64, " + base64;
                })
                .catch((error) => {
                    console.error(error);
                });
            }
        },
        formatPrice: function(value){
            return value != null ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
        },
        formattPrice: function(price, point){
            var vm = this;
            if (vm.isNotNullAndNotUndefined(price) && vm.isUndefined(price) == false) {
                return (price.toFixed(point)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return '-';
            }
        },
        isNull: function(input) {
            return input === null;
        },
        isNotNull: function(input) {
            var vm = this;
            return !vm.isNull(input);
        },
        isUndefined: function(input) {
            return typeof input == 'undefined';
        },
        isNotUndefined: function(input) {
            var vm = this;
            return !vm.isUndefined(input);
        },
        isNotNullAndNotUndefined: function(input) {
            var vm = this;
            return vm.isNotNull(input) && vm.isNotUndefined(input);
        },
        isEmptyString:function( str ){
            if( this.isUndefined(str) || !str ){
                return true;
            }

            return [false,true,null,NaN,""].includes(str)
        },
        showPopupMessage(header, message, buttonStyle, closeBtnMsg, closeFunction){
            var vm = this;
            document.querySelector("#globalPopupModal-button").classList.remove("bg-gradient-primary");
            document.querySelector("#globalPopupModal-button").classList.remove("btn-gradient-success");
            document.querySelector("#globalPopupModal-button").classList.remove("bg-gradient-danger");
            document.querySelector("#globalPopupModal-button").classList.remove("btn-gradient-warning");

            document.querySelector("#globalPopupModal-header").innerText = header;
            document.querySelector("#globalPopupModal-body").innerText = message;
            document.querySelector("#globalPopupModal-button").classList.add(buttonStyle);
            if(closeBtnMsg){
                document.querySelector("#globalPopupModal-button").innerText =closeBtnMsg;
            }else{
                document.querySelector("#globalPopupModal-button").innerText =vm.$t("label.close");
            }

            var modal = vm.modal("#globalPopupModal");
            if( modal ){
                modal.show();
            }

            if (closeFunction && typeof closeFunction === "function") {
                document.querySelector("#globalPopupModal-button").addEventListener('click', closeFunction);
            }else{
                document.querySelector("#globalPopupModal-button").removeEventListener('click', closeFunction);
            }
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if((keyCode < 48 || keyCode > 57) || (keyCode < 96 && keyCode > 105)){
                $event.preventDefault();
            }
        },
        openErrorModal: function(title, desc, errCode) {
            Global.errorModal = {};
            Global.errorModal.title = title;
            Global.errorModal.desc = desc;
            Global.errorModal.errCode = errCode;
            this.modal("#errorModal")?.show();
        },
        _e : function( cssSelector ){
            try{
                return document.querySelector(cssSelector);
            }catch(err){
                console.log(err);
                return null;
            }
        },
        modal:function( cssSelector ){
            try{
                var ele = document.querySelector(cssSelector);
                return ele ? bootstrap.Modal.getOrCreateInstance(ele) : null
            }catch(err){
                console.log(err);
                return null;
            }
        },
        hide2menu: function(){
            document.getElementById("main2area").classList.add("hide2pk");
            document.getElementById("sidenav-main").classList.add("hide2side");
            document.getElementById("hide2menu").classList.add("d-none");
            document.getElementById("show2menu").classList.remove("d-none");
        },
		start2vdo: function() {
			const webcamElement = document.getElementById('webcam');
            if(!Global.webcam){
                Global.webcam = new Webcam(webcamElement, 'user');
                Global.webcam?.start();
                document.getElementById("smile3section").classList.remove("d-none");
            }
        },
        stop2vdo: function() {
            if(Global.webcam){
                document.getElementById("smile3section").classList.add("d-none");
                Global.webcam?.stop();
                Global.webcam = null;
            }
        },
        showNullWithDash(value){
            return !this.isNullOrEmpty(value) ? value : '-';
        },
        topLoading:function(action){
            document.querySelector("#topLoading").style.display = action == "show" ? "block" : "none";
        },
        getRetrieveURL: function(opportunityNumber, productType) {
            var vm = this;
            axios
            .get(Global.apiUrl + "/opportunities/" + opportunityNumber + "/" + productType)
            .then(function(response) {
                    window.open(response.data.retrieve, '_blank');
            })
            .catch(function(error) {
                if(error?.response?.status === 401){
                    vm.$router.go();
                }
                console.log(error);
            });
        },
        getRetrieveURLsetGlobal: function(opportunityNumber, productType) {
            var vm = this;
            axios
            .get(Global.apiUrl + "/opportunities/" + opportunityNumber + "/" + productType)
            .then(function(response) {
                Global.retrieveQuoteURL = response.data.retrieve;
            })
            .catch(function(error) {
                if(error?.response?.status === 401){
                    vm.$router.go();
                }
                console.log(error);
            });
        },
        showBusinessValidate(approvalStatus){
            var vm = this;
            var status = '-';
            if(!vm.isNullOrEmpty(approvalStatus) && approvalStatus.toUpperCase() === ('Rejected').toUpperCase()){
                status = 'notPass';
            }
            return status;
        },
        displayPaymentPlan:function(paymentFrequency){
            var vm = this;
            switch( paymentFrequency ){
            case 'Yearly':
                return vm.$t("fullPayment");
            case '12 months, no deposit':
                return vm.$t("12monthsInstallment");
            case 'Monthly':
                return vm.$t("10monthsInstallment");
            case 'Monthly no deposit':
                return vm.$t("10monthsInstallmentNoDeposit");
            case 'Quarterly':
                return vm.$t("4monthsInstallment");
            case null:
                return '-';
            default :
                return paymentFrequency;
            }
        },
        eventTracking: function(eventCategory, eventAction, eventLabel) {
            window.dataLayer.push({
            event: "gtm.click",
            eventCategory: eventCategory ? eventCategory : this.$route.path,
            eventAction: eventAction,
            eventLabel: eventLabel
            });
        },
        logActivity: function(email, activity, msg, userId) {
            axios.post(Global.apiUrl + "/log/ga", { email: email, activity: activity, msg: msg, user_id: userId });
        },
        validatePassword: function(input1, input2) {
            if (!input1 || !input2) return false;
            var regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{12,50}$/);
            return (input1.match(regex) != null && input1 === input2);
        },
        sfMcsApiUserLogin: async function() {
            if (Global.apiUser.jwt === "") {
                await axios.post(Global.apiUrl + "/login", { username: Global.apiUser.username, password: Global.apiUser.password })
                .then(function(response) {
                  Global.apiUser.jwt = response.data.token;
                })
                .catch(function(error) {
                  console.log(error)
                });
            }
        },
        getBrokerDetails: async function(rid){
            var encryptRid = await axios.get(Global.apiUrl + "/util/encrypt?text=" + rid);
            var response = await axios.get(Global.apiUrl + "/broker-details/details?rid=" + encodeURIComponent(encryptRid.data))
            if(response){
                Global.brokerDetails.accountSource = response.data.accountSource;
                Global.brokerDetails.isTbAdmin = response.data.accountSource === 'Traditional broker' && Global.authority === 'AGENT_ADMIN';
                Global.brokerDetails.firstname = response.data.firstname;
                Global.brokerDetails.lastname = response.data.lastname;
                Global.brokerDetails.intermediaryAccountId = response.data.intermediaryAccountId;
                Global.brokerDetails.intermediaryReptEmail = response.data.intermediaryReptEmail;
                Global.brokerDetails.intermediaryReptId = response.data.intermediaryReptId;
                Global.brokerDetails.isPersonAccount = response.data.isPersonAccount;
                Global.brokerDetails.licenseNumber = response.data.licenseNumber;
                Global.brokerDetails.parentAccountId = response.data.parentAccountId;
                Global.brokerDetails.phone = response.data.phone;
                Global.brokerDetails.type = response.data.type;
                Global.brokerDetails.licenseExpDate = response.data.nonlifeLicenseExpiryDate;
                Global.brokerDetails.bankAccount = response.data.bankAccount;
                Global.brokerDetails.bankName = response.data.bankName;
                Global.brokerDetails.address = response.data.address;
                Global.brokerDetails.gaNumber = response.data.gaNumber;
                Global.brokerDetails.brokerName = response.data.brokerName;
                if(response.data.intermediaryAccountOwnerID){
                    Global.brokerDetails.intermediaryAccountOwnerID = response.data.intermediaryAccountOwnerID;
                }
            }
        },
        generatePayOnlineLink: async function(opportunityNumber){
            var vm = this;
            vm.topLoading("show");
            try{
                var response = await axios.post(
                Global.apiUrl + "/payment/generatePayOnline",
                {
                    opportunityNumber: opportunityNumber
                }
                );
                if(response.data.success){
                    vm.topLoading("hide");
                    vm.showPopupMessage(vm.$t("payOnline.success.title"), vm.$t("payOnline.success.desc"), "bg-gradient-primary");
                }else{
                    vm.topLoading("hide");
                    vm.openErrorModal(vm.$t('payOnline.error.title'), vm.$t('payOnline.error.desc'));
                }
            }catch (error) {
                console.error(error);
                vm.topLoading("hide");
                vm.openErrorModal(vm.$t('payOnline.error.title'), vm.$t('payOnline.error.desc'));
            }
        },
        updateSyncDateTime: async function () {
            return axios.get(Global.apiUrl + "/dashboard/lastUpdatedData")
            .then(function(response){
                //return new Date(response.data);
                return response.data;
            })
            .catch(function(error) {
                console.log(error);
            });
        },        
        submitPdpa: async function() {
            if (Global.apiUser.jwt === "") {                
                await this.sfMcsApiUserLogin();
            }
            if (Global.apiUser.jwt) {
                if (Global.mcsToken == "") await this.getTokenMcs();
                if (Global.mcsToken) {
                    var name = this.getBrokerName();
                    await axios.post(Global.mcsURL + '/pdpa/log', {
                        // gclid: "",
                        clientId: "",            
                        timeStamp: new Date(),
                        referenceId: "",
                        applicationName: "AgentPortal",
                        pageName: this.$route.fullPath,
                        bi: this.getBrowserInformation(),
                        // sessionId: "",
                        customerInfo: name.length<1 ? "" : name
                    }, {headers: { Authorization: "Bearer " + Global.mcsToken }})            
                    .catch(function(error) {
                        console.log('error: '+error);
                    });                
                }
            }
        },        
        getBrokerName: function() {
            var firstname = Global.brokerDetails.firstname!=null ? Global.brokerDetails.firstname : null;
            var lastname = Global.brokerDetails.lastname!=null ? Global.brokerDetails.lastname : null;
            if (firstname==null && lastname==null) {
                return ' ';
            } else {
                return (firstname || '') + ' ' + (lastname || '');
            }
        }, 
        getBrowserInformation: function() {
            return Mdd.osName + ' version [' + Mdd.osVersion + '] - ' + Mdd.browserName + ' version [' + Mdd.fullBrowserVersion + ']';
        },
        getMyAccountToken: function(){
            return axios.post(Global.myAccount.url + "/login",
            {
                username: Global.myAccount.authenUsername,
                password: Global.myAccount.authPassword
            }, {
                headers: {"Authorization": ""}
            });
        },
        copyLinkToClipBoard: function (el) {
            var copyText = document.getElementById(el);
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
        },
        getSystemConfiguration: function(callback){
            var vm = this;
            axios.get(Global.apiUrl + "/configurations/" + Global.email)
            .then(function(response){
                if(response.data){
                    Global.GAEncodeUrl.agent = response.data.agent;
                    Global.GAEncodeUrl.customer = response.data.customer;
                    Global.guidelineUrl = JSON.parse(response.data.guidelineUrl);
                    Global.dashboard.isShowCommission = response.data.isShowCommission;
                }
                if (callback && typeof callback === "function") {
                    callback();
                }
            })
            .catch(function(error) {
                if(error?.response?.status === 401){
                    vm.$router.go();
                }
                console.log(error);
            })
        },
        getPersonalDocs: async function (docNames, isZipped = false) {
            let vm = this;
            if(isZipped){
                vm.topLoading("show");
            }

            let result = [];
            try{
                let response = await axios.post(
                Global.apiUrl + "/util/intermeduaryDocuments",
                {
                    monthYears: docNames,
                    isCompressed: isZipped
                });
                if(response?.data?.success){
                    vm.topLoading("hide");
                    result = response?.data?.dataResponse;
                }else{
                    vm.topLoading("hide");
                }
            }catch (error) {
                vm.topLoading("hide");
                vm.openErrorModal(vm.$t('loadPersonalDocs.error.title'), vm.$t('loadPersonalDocs.error.desc'));
            }
            return result;
        },
        displaySpecifiedProductType(val){
            const prodType = {'Health':'Cancer'};
            return prodType[val]?prodType[val]:val;
        },
        isThairath: function(details){
            console.log('details',details)
            return details.accountSource == 'Affiliate' && details.brokerName == 'Thairath';
        }
    }
}
</script>